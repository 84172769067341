@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply inline-block border text-center px-3 py-2 rounded-md font-semibold shadow-sm focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-0 disabled:bg-secondary-light disabled:text-secondary disabled:border-secondary-border hover:disabled:bg-secondary-light hover:disabled:text-secondary hover:disabled:border-secondary-border;
}

.btn-primary {
  @apply border-primary text-primary-dark bg-primary-light hover:bg-primary hover:text-light focus-visible:bg-primary focus-visible:text-light;
}

.btn-secondary {
  @apply border-secondary text-secondary-dark bg-light hover:bg-secondary hover:text-light focus-visible:bg-secondary focus-visible:text-light;
}

.btn-warning {
  @apply border-warning text-warning-dark bg-warning-light hover:bg-warning hover:text-light focus-visible:bg-warning focus-visible:text-light;
}

.btn-danger {
  @apply border-danger text-danger-dark bg-danger-light hover:bg-danger hover:text-light focus-visible:bg-danger focus-visible:text-light;
}

.btn-secondary.disabled {
  @apply bg-secondary-light pointer-events-none text-secondary border-secondary-border;
}
